import React, { useContext } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, Typography, Tooltip } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import capitalize from 'lodash/capitalize'
import { NavLink } from 'react-router-dom'

import { ReactComponent as MinShowableResultsIcon } from 'assets/img/min-showable-results.svg'
import * as NpsBreakdownImage from 'assets/img/NPS-score-breakdown.png'
import FormPanel, { EXPAND_ICON } from 'components/Blocks/Accordions/FormPanel'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ScreenshotButton from 'components/Blocks/CustomButtons/ScreenshotButton'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { NPS_THRESHOLDS } from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import { gaEvent } from 'config/ga'
import { StoreContext } from 'config/LocalStorage'
import {
  CurrentUserDocument,
  InsightsDownloadDocument,
  NpsGroupsEnum,
  CurrentUserQuery,
  NpsGroupScoresType,
  InsightsModulesEnum,
  SurveyProductTypeEnum,
  BenchmarkCodeType,
  UserDownloadsEnum,
  useInsightsStatementBenchmarkQuery,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import {
  runDownloadQuery,
  getNpsLabel,
  getNpsAbbreviation,
  getSolutionFromProductType,
  formatTooltipScore,
} from 'utils'
import { getInsightsPage } from 'utils/insightsUtils'

export type NPSScore = {
  [NpsGroupsEnum.DETRACTORS]: number
  [NpsGroupsEnum.PASSIVES]: number
  [NpsGroupsEnum.PROMOTERS]: number
}

export const NPS_COLORS = {
  [NpsGroupsEnum.DETRACTORS]: '#F36A6B',
  [NpsGroupsEnum.PASSIVES]: '#F7D35C',
  [NpsGroupsEnum.PROMOTERS]: '#4DB5AF',
}

export const npsGroups = [NpsGroupsEnum.DETRACTORS, NpsGroupsEnum.PASSIVES, NpsGroupsEnum.PROMOTERS]

const NPS_SNAP = 'npsSnap'

const useTooltipStyles = makeStyles(({ spacing }) => ({
  container: {
    width: 300,
    whiteSpace: 'pre-wrap',
    padding: spacing(2),
    fontVariantNumeric: 'lining-nums',
  },
  rowsBlock: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    paddingRight: spacing(1),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  leftBlock: {
    width: '45%',
  },
  rightBlock: {
    width: '55%',
    display: 'flex',
    '& >div': {
      width: '50%',
    },
  },
}))

const useStyles = makeStyles(({ palette, spacing }) => ({
  icon: {
    color: palette.common.navy65,
    width: 40,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& >p': {
      width: '80%',
    },
    marginTop: -spacing(3),
    alignItems: 'center',
  },
  gaugeAndScores: {
    display: 'flex',
  },
  npsChart: {
    width: 400,
    height: 300,
    marginLeft: 20,
    marginRight: 90,
    marginTop: -20,
    marginBottom: -100,
  },
  detailsBox: {
    width: '100%',
    '& >div': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  questionText: {
    width: '60%',
    '@media print': {
      width: '50%',
    },
    marginRight: spacing(2),
  },
  scoresBox: {
    width: '40%',
    '@media print': {
      width: '50%',
    },
  },
  scoreRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: spacing(),
  },
  scoreRowScore: {
    width: '20%',
  },
  scoreDot: {
    width: 10,
    height: 10,
    marginRight: spacing(),
    borderRadius: '50%',
  },
  tooltipContainer: {
    margin: spacing(3),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: -spacing(2),
  },
}))

export const NpsHeaderTooltip: React.FC<{ productType: SurveyProductTypeEnum }> = ({
  productType,
}) => {
  const classes = useStyles()

  const npsAbbr = getNpsAbbreviation(productType)
  return (
    <div className={classes.tooltipContainer}>
      <Typography>{getNpsLabel(productType)} Question:</Typography>
      <Typography color="textSecondary">
        Respondents are given a linear scale rating (0 to 10) to answer the {npsAbbr} question.
        Detractors (0-6), Passives (7-8), and Promoters (9-10) - showing you the % breakdown of
        customers who are loyal, are neutral, or are unhappy.
      </Typography>
      <br />
      <Typography>How we calculate the {npsAbbr} Score:</Typography>
      <Typography color="textSecondary">
        Subtract % detractors from the % promoters = {npsAbbr} score
      </Typography>
      <br />
      <img width="100%" src={NpsBreakdownImage} alt="Nps Breakdown" />
      <br />
      <br />
      <Typography color="textSecondary">
        The {npsAbbr} score ranges from -100 to 100, (all percentages are rounded up to the nearest
        whole number). A negative {npsAbbr} score lets you know there are more detractors than
        promoters. An {npsAbbr} score of 50 or higher is considered above average. Any score above{' '}
        {npsAbbr} 70 is excellent, and should be celebrated.
      </Typography>
    </div>
  )
}

type NpsGauageProps = {
  surveyName: string
  npsBenchmark?: null | NPSScore
  npsScore: NPSScore
  npsAbbr: string
}

export const getNpsOverallScore = (npsScore: NPSScore) =>
  npsScore[NpsGroupsEnum.PROMOTERS] - npsScore[NpsGroupsEnum.DETRACTORS]

type NpsTooltipProps = {
  title: string
  surveyName: string
  npsScore: NPSScore
  npsBenchmark?: null | NPSScore
  minShowableResults?: number
}

export const NpsTooltip: React.FC<NpsTooltipProps> = ({
  title,
  surveyName,
  npsScore,
  npsBenchmark,
  minShowableResults = 0,
}) => {
  const classes = useTooltipStyles()

  const rows = [
    { label: '', value: 'Current', benchmarkValue: 'Benchmark', color: colors.navy },
    {
      label: 'NPS Score',
      value: Math.round(getNpsOverallScore(npsScore)),
      benchmarkValue: npsBenchmark ? Math.round(getNpsOverallScore(npsBenchmark)) : '-',
      color: colors.navy65,
    },
    ...npsGroups.map(group => ({
      label: capitalize(group),
      value: formatTooltipScore(npsScore[group], minShowableResults),
      benchmarkValue: npsBenchmark ? Math.round(npsBenchmark[group]) : 1,
      color: NPS_COLORS[group],
    })),
  ]

  return (
    <div className={classes.container}>
      <div>{title}</div>
      <div className={classes.rowsBlock}>
        {rows.map(({ label, value, benchmarkValue, color }) => {
          return (
            <div className={classes.row} key={label}>
              <div className={classes.leftBlock}>
                <Typography color="textSecondary">{label}</Typography>
              </div>
              <div className={classes.rightBlock}>
                <div style={{ color }}>{value}</div>
                <div style={{ color: colors.navy65 }}>{benchmarkValue}</div>
              </div>
            </div>
          )
        })}
      </div>
      <Typography variant="body2" color="textSecondary">
        {`Survey: ${surveyName}`}
      </Typography>
    </div>
  )
}

const NpsGauge: React.FC<NpsGauageProps> = ({ surveyName, npsAbbr, npsScore, npsBenchmark }) => {
  const classes = { ...useStyles(), ...useTooltipStyles() }
  // In the chart, the gauge values are measured as a distance from the 0 point that's why we need to sum them up this way
  const gaugeValues = {
    [NpsGroupsEnum.DETRACTORS]: npsScore[NpsGroupsEnum.DETRACTORS],
    [NpsGroupsEnum.PASSIVES]: npsScore[NpsGroupsEnum.DETRACTORS] + npsScore[NpsGroupsEnum.PASSIVES],
    [NpsGroupsEnum.PROMOTERS]:
      npsScore[NpsGroupsEnum.DETRACTORS] +
      npsScore[NpsGroupsEnum.PASSIVES] +
      npsScore[NpsGroupsEnum.PROMOTERS], // This adds up to 100
  }
  const benchmarkGaugeValues = npsBenchmark && {
    [NpsGroupsEnum.DETRACTORS]: npsBenchmark[NpsGroupsEnum.DETRACTORS],
    [NpsGroupsEnum.PASSIVES]:
      npsBenchmark[NpsGroupsEnum.DETRACTORS] + npsBenchmark[NpsGroupsEnum.PASSIVES],
    [NpsGroupsEnum.PROMOTERS]:
      npsBenchmark[NpsGroupsEnum.DETRACTORS] +
      npsBenchmark[NpsGroupsEnum.PASSIVES] +
      npsBenchmark[NpsGroupsEnum.PROMOTERS], // This adds up to 100
  }

  // Mathematically calculating the position of the percentage labels
  const labelValues = {
    [NpsGroupsEnum.DETRACTORS]: Math.round(gaugeValues[NpsGroupsEnum.DETRACTORS] / 2),
    [NpsGroupsEnum.PASSIVES]: Math.round(
      (gaugeValues[NpsGroupsEnum.DETRACTORS] + gaugeValues[NpsGroupsEnum.PASSIVES]) / 2,
    ),
    [NpsGroupsEnum.PROMOTERS]: Math.round(
      (gaugeValues[NpsGroupsEnum.PASSIVES] + gaugeValues[NpsGroupsEnum.PROMOTERS]) / 2,
    ),
  }

  const NPSSeries = {
    type: 'gauge',
    startAngle: 180,
    endAngle: 0,
    min: 0,
    max: 100,
    // Splitting the 180 degrees in 100 chunks so we can place the percentage labels using the calculations above
    splitNumber: 100,
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    pointer: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        width: 12,
        color: npsGroups.map(group => [gaugeValues[group] / 100, NPS_COLORS[group]]),
      },
    },
    axisLabel: {
      color: colors.navy,
      fontSize: 14,
      distance: -30,
      rotate: 'tangential',
      formatter: (value: number) => {
        const labelByValue = npsGroups.reduce((acc, group) => {
          acc[labelValues[group]] = npsScore[group]
          return acc
        }, {} as Record<number, number>)

        const val = labelByValue[value]
        if (val === 0) {
          return '0%'
        }
        return val && `${Math.round(val)}%`
      },
    },
    data: [
      {
        name: `${npsAbbr} ${Math.round(getNpsOverallScore(npsScore))}`,
        title: {
          color: colors.navy,
          offsetCenter: [0, '-30%'],
          fontSize: 30,
        },
        detail: {
          valueAnimation: true,
          color: colors.navy,
          offsetCenter: [0, '-40%'],
          show: false,
        },
      },
    ],
  }

  const NPSBenchmarkSeries = benchmarkGaugeValues && {
    type: 'gauge',
    startAngle: 180,
    endAngle: 0,
    min: 0,
    max: 100,
    // Splitting the 180 degrees in 100 chunks so we can place the percentage labels using the calculations above
    splitNumber: 5,
    axisTick: {
      length: 2,
      lineStyle: {
        color: 'auto',
        width: 5,
      },
    },
    splitLine: {
      show: false,
    },
    pointer: {
      show: false,
    },
    radius: '70%',
    axisLine: {
      show: false,
      lineStyle: {
        width: 3,
        color: npsGroups.map(group => [benchmarkGaugeValues[group] / 100, NPS_COLORS[group]]),
      },
    },
    axisLabel: {
      fontSize: 0,
    },
  }

  return (
    <Tooltip
      placement="right-start"
      title={
        <NpsTooltip
          title="Company Overall NPS"
          surveyName={surveyName}
          npsScore={npsScore}
          npsBenchmark={npsBenchmark}
        />
      }
    >
      <div className={classes.npsChart}>
        <PrintableEchart
          printWidth={250}
          opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
          option={{
            textStyle: chartTextStyle,
            series: [NPSSeries, NPSBenchmarkSeries],
          }}
        />
      </div>
    </Tooltip>
  )
}

const getScoreDescription = (score: number) => {
  if (score < 0) {
    return { color: NPS_COLORS[NpsGroupsEnum.DETRACTORS], label: 'Needs Improvement' }
  }
  if (score < 30) {
    return { color: colors.mustardYellow, label: 'Good' }
  }
  if (score < 70) {
    return { color: NPS_COLORS[NpsGroupsEnum.PROMOTERS], label: 'Great' }
  }
  return { color: '#2D9D96', label: 'Excellent' }
}

const getNpsDescription = (productType: SurveyProductTypeEnum) => {
  const word = productType === SurveyProductTypeEnum.EMPLOYEE ? 'employee' : 'customer'
  return `The ${getNpsAbbreviation(
    productType,
  )} question seeks to understand overall ${word} loyalty for your organization.`
}

type NpsRecommendControlsProps = {
  surveyUuid: string
  productType: SurveyProductTypeEnum
  filters: string[]
  hideScreenShotButton?: boolean
}

const NpsRecommendControls: React.FC<NpsRecommendControlsProps> = ({
  surveyUuid,
  productType,
  filters,
  hideScreenShotButton,
}) => {
  const classes = useStyles()
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser as NonNullable<
    CurrentUserQuery
  >['currentUser']
  const npsDownload = Boolean(
    getSolutionFromProductType(currentUser.organization, productType).npsDownload,
  )
  return (
    <div className={classes.actions}>
      {npsDownload && (
        <IconButton
          id="nps-download"
          color="secondaryHover"
          className={classes.icon}
          onClick={() => {
            gaEvent({
              action: `download-NPS`,
              category: 'Insights',
            })
            runDownloadQuery(() =>
              client.query({
                query: InsightsDownloadDocument,
                variables: {
                  surveyUuid,
                  filters,
                  downloadType: UserDownloadsEnum.NPS_EXPORT,
                },
                fetchPolicy: 'network-only',
              }),
            )
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      )}
      {!hideScreenShotButton && <ScreenshotButton snapId={NPS_SNAP} strategy="html2canvas" />}
    </div>
  )
}

type NpsCardProps = {
  survey: InsightsSurvey
  productType: SurveyProductTypeEnum
  filters: string[]
  npsGroupScores: NpsGroupScoresType
  useFormPanel?: boolean
  npsBenchmark?: null | NPSScore
  benchmarkName?: string
}
const NpsRecommendCard: React.FC<NpsCardProps> = ({
  survey,
  npsBenchmark,
  productType,
  filters,
  npsGroupScores,
  useFormPanel,
  benchmarkName,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }

  if (!npsGroupScores.totalResponses) {
    return (
      <EmptyState
        title=""
        description="Not enough data to display NPS results"
        Icon={MinShowableResultsIcon}
      />
    )
  }

  const { promoters, passives, detractors, label } = npsGroupScores
  const npsAbbr = getNpsAbbreviation(productType)
  const npsScore = {
    [NpsGroupsEnum.DETRACTORS]: detractors,
    [NpsGroupsEnum.PASSIVES]: passives,
    [NpsGroupsEnum.PROMOTERS]: promoters,
  }
  const scoreDescription = getScoreDescription(getNpsOverallScore(npsScore))

  return (
    <>
      <div id={NPS_SNAP} className={classes.pageBreak}>
        {useFormPanel && (
          <div className={classes.header}>
            {/** Snapshot Chart header displays these buttons as a header.
             * On the form panel, we want to only display them when the panel is expanded */}
            <Typography color="textSecondary">{getNpsDescription(productType)}</Typography>
            <NpsRecommendControls
              surveyUuid={survey.uuid}
              productType={productType}
              filters={filters}
            />
          </div>
        )}
        <div className={classes.gaugeAndScores}>
          <NpsGauge
            surveyName={survey.name}
            npsAbbr={npsAbbr}
            npsBenchmark={npsBenchmark}
            npsScore={npsScore}
          />
          <div className={classes.detailsBox}>
            <Typography style={{ color: scoreDescription.color }}>
              {scoreDescription.label.toUpperCase()}
            </Typography>
            <div>
              <div className={classes.questionText}>
                <Typography>{npsAbbr} Question:</Typography>
                <Typography color="textSecondary">{label}</Typography>
              </div>
              <div className={classes.scoresBox}>
                {npsGroups.map(group => (
                  <div key={group} className={classes.scoreRow} id={`score-row-${group}`}>
                    <div
                      className={classes.scoreDot}
                      style={{
                        backgroundColor: NPS_COLORS[group],
                      }}
                    />
                    <Typography className={classes.scoreRowScore}>
                      {Math.round(npsScore[group])}%
                    </Typography>
                    <Typography color="textSecondary">
                      {capitalize(group)} ({NPS_THRESHOLDS[group][0]}-{NPS_THRESHOLDS[group][1]})
                    </Typography>
                  </div>
                ))}
                {npsBenchmark && (
                  <div className={classes.scoreRow}>
                    {npsGroups.map(group => {
                      return (
                        <span key={group} style={{ color: NPS_COLORS[group] }}>
                          &mdash;&nbsp;
                        </span>
                      )
                    })}
                    &nbsp;{benchmarkName}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const NpsRecommendCardContainer: React.FC<NpsCardProps & {
  showCommentsLink?: boolean
}> = props => {
  const { productType, showCommentsLink, survey, useFormPanel, filters, npsGroupScores } = props
  const { store } = useContext(StoreContext)
  const benchmark = store.residentInsightsBenchmark || survey.defaultBenchmark

  const result = useInsightsStatementBenchmarkQuery({
    variables: {
      benchmarkUuid: benchmark?.benchmarkUuid || '',
      statementCode: BenchmarkCodeType.NPS_RECOMMEND,
    },
  })

  let commentsSection = null
  if (showCommentsLink && npsGroupScores.totalResponses) {
    const npsAbbr = getNpsAbbreviation(productType)
    commentsSection = (
      <Typography color="textSecondary">
        To read open ended feedback that answers the follow up {npsAbbr} question: Why did you give
        [Location Name] this score?{' '}
        <NavLink
          to={`${getInsightsPage(
            survey.uuid,
            InsightsModulesEnum.COMMENTS,
            SurveyProductTypeEnum.RESIDENT,
          )}?questionCode=${BenchmarkCodeType.NPS_RECOMMEND_FOLLOWUP}`}
        >
          See Comments
        </NavLink>
      </Typography>
    )
  }

  const title = `Company Overall ${getNpsLabel(productType)}`

  const NPSRecommendComponent = (
    <ResponseHandler {...result} skip={!benchmark}>
      {({ insightsStatementBenchmark }) => {
        return (
          <NpsRecommendCard
            npsBenchmark={
              insightsStatementBenchmark && {
                [NpsGroupsEnum.PROMOTERS]: insightsStatementBenchmark.positive || 0,
                [NpsGroupsEnum.PASSIVES]: insightsStatementBenchmark.inconsistent || 0,
                [NpsGroupsEnum.DETRACTORS]: insightsStatementBenchmark.negative || 0,
              }
            }
            benchmarkName={insightsStatementBenchmark?.residentBenchmark?.name}
            {...props}
          />
        )
      }}
    </ResponseHandler>
  )

  if (useFormPanel) {
    return (
      <FormPanel
        id="nps-card-panel"
        expanded
        removeDetailsTopPadding
        gutterBottom={false}
        expandIcon={EXPAND_ICON.EXPAND}
        summary={
          <SnapshotChartHeader
            title={title}
            tooltip={<NpsHeaderTooltip productType={productType} />}
            useBottomPadding={false}
          />
        }
      >
        {NPSRecommendComponent}
        {commentsSection}
      </FormPanel>
    )
  }
  return (
    <>
      <SnapshotChartHeader
        title={title}
        tooltip={<NpsHeaderTooltip productType={productType} />}
        description={getNpsDescription(productType)}
        snapId={NPS_SNAP}
        screenshotStrategy="html2canvas"
        extraControls={
          <NpsRecommendControls
            surveyUuid={survey.uuid}
            productType={productType}
            filters={filters}
            hideScreenShotButton
          />
        }
      />
      {NPSRecommendComponent}
      {commentsSection}
    </>
  )
}

export default NpsRecommendCardContainer
