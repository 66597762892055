import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import NpsBreakdownCard from 'components/Insights/Blocks/NPS/NpsBreakdownCard'
import NpsRecommendCard from 'components/Insights/Blocks/NPS/NpsRecommendCard'
import RankedStatementsCard from 'components/Insights/Blocks/RankedStatementsCard'
import SeeMore from 'components/Insights/Blocks/StatementsSeeMoreButton'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import LonelinessCard from 'components/Insights/ResidentSnapshot/LonelinessCard'
import ResidentKeyFocusAreasCard from 'components/Insights/ResidentSnapshot/ResidentKeyFocusAreasCard'
import ServicesChart from 'components/Insights/ResidentSnapshot/ServicesChart'
import ResponseRateContainer from 'components/Insights/ResponseRate/ResponseRateContainer'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import OverallIndexCard from 'components/Insights/Snapshot/OverallIndexCard'
import TTDetailedBreakdownCard from 'components/Insights/TimeTrending/TTDetailedBreakdownCard'
import TTIndexScoreResponseCard from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import TTKeyDemographicsCard from 'components/Insights/TimeTrending/TTKeyDemographicsCard'
import TTLonelinessCard from 'components/Insights/TimeTrending/TTLonelinessCard'
import TTScatterAndTableCard from 'components/Insights/TimeTrending/TTScatterAndTableCard'
import TTServicesCard from 'components/Insights/TimeTrending/TTServicesCard'
import TTSnapshotStatementsCard from 'components/Insights/TimeTrending/TTSnapshotStatementsCard'
import { getValidTimeTrendingSurveys } from 'components/Insights/TimeTrending/utils'
import { TimeTrendingChartKey, StoreContext } from 'config/LocalStorage'
import {
  InsightsModulesEnum,
  InsightsStatementScoreFragment,
  InsightsStatementsQuery,
  useInsightsSnapshotOverviewQuery,
} from 'generated/graphql'
import { RANKED_STATEMENTS_LIMIT, RESPONSE_TYPES, ORDER_TYPES } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(4),
    width: 300,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}))

export const getBottomResidentScores = (
  statements: Array<InsightsStatementsQuery['statements'][0] | InsightsStatementScoreFragment>,
) => {
  const orderedStatements = orderBy(statements, ['negative', 'label'], ORDER_TYPES.DESCENDING)
  // Limit dining to a single statement (in sync with us_news/pages/opportunities_page.py)
  return uniqBy(orderedStatements, s => s.code.split('dining')[0]).slice(0, RANKED_STATEMENTS_LIMIT)
}

const ResidentEngagementOrPulseSnapshot: React.FC<InsightsTabProps> = props => {
  // Most browsers have a limit of 6 concurrent connections. In order to provide the optimal user experience
  // we want to quickly load data for the first set of charts so the user has something to look at while
  // we load the other charts. The remaining charts, we load in groups, so that we stay under the concurrency limit.
  const {
    survey,
    availableSurveys,
    filters,
    benchmark,
    currentUser,
    solution,
    timeTrendingType,
  } = props
  const {
    store: {
      [TimeTrendingChartKey.RESIDENT_INDEX_SCORE]: showIndexScoreTimeTrending,
      [TimeTrendingChartKey.RESIDENT_DETAILED_BREAKDOWN]: showDetailedBreakdownTimeTrending,
      [TimeTrendingChartKey.RESIDENT_SERVICES]: showServicesTimeTrending,
      [TimeTrendingChartKey.RESIDENT_SNAPSHOT_STATEMENTS]: showStatementsTimeTrending,
      [TimeTrendingChartKey.RESIDENT_KEY_DEMOGRAPHICS]: showKeyDemographicsTimeTrending,
      [TimeTrendingChartKey.RESIDENT_LONELINESS]: showLonelinessTimeTrending,
    },
  } = useContext(StoreContext)
  const hasAccessToExtendedSnapshot = solution.insightsSnapshots
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
      skipNpsScore: !survey.includesNpsQuestion,
    },
  })
  const validLonelinessSurveys = getValidTimeTrendingSurveys(availableSurveys).filter(
    s => s.includesLonelinessQuestions,
  )
  const hasTimeTrending = Boolean(timeTrendingType)
  return (
    <>
      <BreadcrumbsItem
        to={getInsightsPage(survey.uuid, InsightsModulesEnum.SNAPSHOTS, survey.productType)}
      >
        Snapshot
      </BreadcrumbsItem>
      <ResponseHandler {...result}>
        {({ insightsStatements, insightsOverallIndex, insightsNpsGroupScores }) => {
          const overallPositive = Math.round(insightsOverallIndex.positive)
          const benchmarkPositive = Math.round(insightsOverallIndex.benchmarkPositive || 0)
          const positiveStatements = orderBy(
            insightsStatements,
            ['positive', 'label'],
            ORDER_TYPES.DESCENDING,
          )
          const bottomStatementsSlice = getBottomResidentScores(insightsStatements)

          return (
            <>
              {showIndexScoreTimeTrending ? (
                <div className={classes.fullRow} id="tt-index-score-snap">
                  <TTIndexScoreResponseCard {...props} />
                </div>
              ) : (
                <div className={classes.halfRow}>
                  <OverallIndexCard
                    title="Engagement Score"
                    benchmarkName={benchmark.name}
                    insightsOverallIndex={insightsOverallIndex}
                  />
                  <ResponseRateContainer
                    survey={survey}
                    filterValueUuids={filters}
                    hasTimeTrending={hasTimeTrending}
                  />
                </div>
              )}
              {hasAccessToExtendedSnapshot && (
                <>
                  {showDetailedBreakdownTimeTrending ? (
                    <>
                      <TTDetailedBreakdownCard {...props} />
                      <TTScatterAndTableCard {...props} companyOverallPositive={overallPositive} />
                    </>
                  ) : (
                    <DetailedBreakdownCard
                      {...props}
                      hasTimeTrending={hasTimeTrending}
                      title="Detailed Breakdown"
                      description="Scores varied across your organization - focus on the teams that most need support to increase your overall score."
                      tooltip="This chart shows customer engagement scores across your organization. The engagement score is the positive score across all core statements and services for the filter that you're looking at."
                      benchmarkPositive={benchmarkPositive}
                      overallPositiveScore={overallPositive}
                    />
                  )}
                  {showServicesTimeTrending ? (
                    <TTServicesCard {...props} />
                  ) : (
                    <ServicesChart
                      {...props}
                      statements={insightsStatements}
                      overallPositiveScore={overallPositive}
                      hasTimeTrending={hasTimeTrending}
                    />
                  )}
                </>
              )}
              {showStatementsTimeTrending ? (
                <TTSnapshotStatementsCard {...props} />
              ) : (
                <>
                  <div className={classes.fullRow} id="top-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      scoreKey="positive"
                      benchmarkScoreKey="benchmarkPositive"
                      statements={positiveStatements.slice(0, RANKED_STATEMENTS_LIMIT)}
                      title="Top Statements"
                      tooltipText="These are the statements with the highest percentage of positive responses."
                      description="There’s a lot to celebrate! Make sure your team knows what they are doing well."
                      // Only the top chart shows the time trending icon.
                      hasTimeTrending={hasTimeTrending}
                      timeTrendingChartKey={TimeTrendingChartKey.RESIDENT_SNAPSHOT_STATEMENTS}
                    />
                    <SeeMore
                      solution={solution}
                      survey={survey}
                      responseType={RESPONSE_TYPES.pos}
                    />
                  </div>
                  <div className={classes.fullRow} id="bottom-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      statements={bottomStatementsSlice}
                      scoreKey="negative"
                      benchmarkScoreKey="benchmarkNegative"
                      title="Recommended Focus Areas"
                      description="To improve your overall customer experience, pick 1-2 of your lowest scoring statements to focus on."
                      tooltipText="These are the statements with the highest percentage of negative responses."
                      snapId="bottom-statements-snap"
                    />
                    <SeeMore
                      solution={solution}
                      survey={survey}
                      responseType={RESPONSE_TYPES.neg}
                    />
                  </div>
                </>
              )}
              {hasAccessToExtendedSnapshot && (
                <ResidentKeyFocusAreasCard
                  {...props}
                  minShowableResults={survey.minShowableResults}
                  surveyUuid={survey.uuid}
                  surveyProductType={survey.productType}
                  bottomStatements={bottomStatementsSlice.slice(0, 3)}
                  allStatements={insightsStatements}
                  overallIndexScore={insightsOverallIndex.positive}
                  hasKeyFocusAreaCarousel={currentUser.organization.hasKeyFocusAreaCarousel}
                />
              )}
              {hasAccessToExtendedSnapshot &&
                solution.insightsKeyDemographics &&
                (showKeyDemographicsTimeTrending ? (
                  <TTKeyDemographicsCard {...props} />
                ) : (
                  <KeyDemographics
                    survey={survey}
                    filters={filters}
                    surveyProductType={survey.productType}
                    benchmark={benchmark}
                    hasTimeTrending={hasTimeTrending}
                  />
                ))}
              {currentUser.canAccessLonelinessResults && survey.includesLonelinessQuestions && (
                <>
                  {showLonelinessTimeTrending ? (
                    <TTLonelinessCard {...props} />
                  ) : (
                    <LonelinessCard
                      surveyUuid={survey.uuid}
                      minShowableResults={survey.minShowableResults}
                      filters={filters}
                      hasTimeTrending={hasTimeTrending && validLonelinessSurveys.length > 1}
                    />
                  )}
                </>
              )}
              {survey.includesNpsQuestion && (
                <>
                  <NpsRecommendCard
                    survey={survey}
                    productType={survey.productType}
                    filters={filters}
                    npsGroupScores={insightsNpsGroupScores}
                    useFormPanel
                    showCommentsLink={hasAccessToExtendedSnapshot}
                  />
                  {hasAccessToExtendedSnapshot && (
                    <NpsBreakdownCard
                      isSnapshotNps
                      survey={survey}
                      filterTypes={props.visibleFilterTypes}
                      filters={filters}
                    />
                  )}
                </>
              )}
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default ResidentEngagementOrPulseSnapshot
