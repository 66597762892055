import React, { useState, ReactElement } from 'react'

import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import sortBy from 'lodash/sortBy'

import Carousel from 'components/Blocks/Carousel'
import HorizontalBarChart, {
  BENCHMARK_LINE_TYPES,
  formatChartData,
  TooltipSeries,
} from 'components/Blocks/Charts/HorizontalBarChart'
import Button from 'components/Blocks/CustomButtons/Button'
import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { NPS_COLORS } from 'components/Insights/Blocks/NPS/NpsRecommendCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsBenchmark } from 'components/Insights/InsightsTypes'
import KeyDemographicsModalChart from 'components/Insights/Snapshot/KeyDemographicsModalChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import StandardTooltip, {
  TooltipRow,
} from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import { gaEvent } from 'config/ga'
import { TimeTrendingChartKey } from 'config/LocalStorage'
import {
  useInsightsKeyDemographicsQuery,
  SurveyProductTypeEnum,
  InsightsSurveyQuery,
  DataTypeCode,
  ImpactScoreStrategyEnum,
  BenchmarkCodeType,
  SurveyTypeEnum,
  NpsGroupsEnum,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore, numberWithCommas, getNpsLabel, getNpsAbbreviation } from 'utils'
import { DATA_TYPE_NAMES, MIN_SHOWABLE_RESULTS_CODE } from 'utils/constants'

// Dropdown Options for how to score demographics in the chart.
// These map to impactScoreStrategy and statementCodes sent to the backend.
enum ImpactStrategyType {
  INDEX_SCORE = 'INDEX_SCORE',
  NPS_SCORE = 'NPS_SCORE',
  CORE_Q = 'CORE_Q',
  GPTW = 'GPTW',
  NUM_RESPONSES = 'NUM_RESPONSES',
}

const useStyles = makeStyles(theme => ({
  chartContainer: {
    width: '100%',
    background: theme.palette.common.iceGrey50,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    position: 'relative',
  },
  chartHeader: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& >h6': {
      fontSize: '1.8rem',
    },
  },
  seeMoreModalButton: {
    padding: 0,
    margin: 0,
  },
  chartSubtitle: {
    marginTop: theme.spacing(1),
  },
}))

// Determine impact score strategies relevant to the survey based on survey type / NPS inclusion.
const getValidStrategies = (survey: InsightsSurveyQuery['survey']) => {
  let validStrategies: ImpactStrategyType[] = []
  if (survey.productType === SurveyProductTypeEnum.RESIDENT) {
    validStrategies = [
      ImpactStrategyType.INDEX_SCORE,
      ImpactStrategyType.CORE_Q,
      ImpactStrategyType.NUM_RESPONSES,
    ]
    if (survey.type !== SurveyTypeEnum.RESIDENT_DISCHARGE) {
      validStrategies.push(ImpactStrategyType.NPS_SCORE)
    }
  } else {
    validStrategies = [
      ImpactStrategyType.INDEX_SCORE,
      ImpactStrategyType.GPTW,
      ImpactStrategyType.NUM_RESPONSES,
    ]
  }
  if (![SurveyTypeEnum.RESIDENT_ENGAGEMENT, SurveyTypeEnum.TI].includes(survey.type)) {
    validStrategies = validStrategies.filter(t => t !== ImpactStrategyType.INDEX_SCORE)
  }
  if (!survey.includesNpsQuestion) {
    validStrategies = validStrategies.filter(t => t !== ImpactStrategyType.NPS_SCORE)
  }
  return validStrategies
}

const getImpactStrategyQueryVars = (impactDropdownStrategy: ImpactStrategyType) => {
  let statementCodes: string[] = []
  let impactScoreStrategy = ImpactScoreStrategyEnum.POSITIVE_SCORE
  if (impactDropdownStrategy === ImpactStrategyType.CORE_Q) {
    statementCodes = [BenchmarkCodeType.CORE_Q1_RECOMMEND]
  } else if (impactDropdownStrategy === ImpactStrategyType.GPTW) {
    statementCodes = [BenchmarkCodeType.TI_60]
  } else if (impactDropdownStrategy === ImpactStrategyType.NPS_SCORE) {
    impactScoreStrategy = ImpactScoreStrategyEnum.NPS_SCORE
  } else if (impactDropdownStrategy === ImpactStrategyType.NUM_RESPONSES) {
    impactScoreStrategy = ImpactScoreStrategyEnum.NUM_RESPONSES
  }
  return { statementCodes, impactScoreStrategy }
}

const getStrategyLabel = (strategy: ImpactStrategyType, productType: SurveyProductTypeEnum) => {
  return {
    INDEX_SCORE:
      productType === SurveyProductTypeEnum.EMPLOYEE
        ? 'Trust Index Score (TIS)'
        : 'Customer Engagement Score (CES)',
    NPS_SCORE: getNpsLabel(productType),
    CORE_Q: 'Recommendation Score',
    GPTW: 'Great Place to Work Statement',
    NUM_RESPONSES: 'Question Responses',
  }[strategy]
}

const getHeaderDescription = (strategy: ImpactStrategyType, productType: SurveyProductTypeEnum) => {
  const indexScoreLabel =
    productType === SurveyProductTypeEnum.EMPLOYEE
      ? 'Trust Index Score'
      : 'Customer Engagement Score'
  return {
    [ImpactStrategyType.INDEX_SCORE]: `Values indicate the average ${indexScoreLabel} for each demographic group. We are using the ${indexScoreLabel} as the key indicator for how participants feel.`,
    [ImpactStrategyType.GPTW]:
      'Values indicate the average statement score for each demographic group. We are using the Great Place to Work statement, “Taking everything into account, I would say this is a great place to work” as the key indicator for how participants feel.',
    [ImpactStrategyType.CORE_Q]:
      'Values indicate the average statement score for each demographic group. We are using the statement, “In recommending this facility to your friends and family, how would you rate it overall?” as the key indicator for how participants feel.',
    [ImpactStrategyType.NPS_SCORE]: `Values indicate the average Net Promoter Score for each demographic group. We are using the ${getNpsAbbreviation(
      productType,
    )} score as the key indicator for how participants feel.`,
    [ImpactStrategyType.NUM_RESPONSES]:
      'Track responses for all the demographic questions asked in the survey. Hover over the answer options to see the response count, and percentages. Use the arrows to click through each question, giving you a better understanding of the overall results.',
  }[strategy]
}

type Props = {
  survey: InsightsSurveyQuery['survey']
  filters: string[]
  surveyProductType: SurveyProductTypeEnum
  benchmark: InsightsBenchmark
  hasTimeTrending?: boolean
  startDate?: string
  endDate?: string
  tooltip?: string | ReactElement
  statementCodes?: BenchmarkCodeType[]
}
const KeyDemographics: React.FC<Props> = ({
  survey,
  filters,
  surveyProductType,
  benchmark,
  hasTimeTrending,
  startDate,
  endDate,
  tooltip: initialTooltip,
}) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const validStrategies = getValidStrategies(survey)
  const [impactDropdownStrategy, setImpactDropdownStrategy] = useState<ImpactStrategyType>(
    validStrategies[0],
  )
  // Maintain on state for the carousel step so we know which chart to use for the screenshot.
  const [step, setStep] = useState(0)
  // When modal demographic is set, the modal opens to show statement scores for that demographic.
  const [modalDemographic, setModalDemographic] = useState<null | DataTypeCode>(null)

  const { statementCodes, impactScoreStrategy } = getImpactStrategyQueryVars(impactDropdownStrategy)
  const result = useInsightsKeyDemographicsQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      statementCodes,
      impactScoreStrategy,
      startDate,
      endDate,
    },
  })
  const timeTrendingChartKey = {
    [SurveyProductTypeEnum.RESIDENT]: TimeTrendingChartKey.RESIDENT_KEY_DEMOGRAPHICS,
    [SurveyProductTypeEnum.EMPLOYEE]: TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS,
  }[surveyProductType]
  const title = `${getStrategyLabel(impactDropdownStrategy, survey.productType)} by Demographics`
  const description = getHeaderDescription(impactDropdownStrategy, survey.productType)
  let tooltip = initialTooltip
  if (!initialTooltip && impactDropdownStrategy !== ImpactStrategyType.NUM_RESPONSES) {
    tooltip =
      'These charts show the overall satisfaction for each demographic group. Focus on reducing variablility between groups to ensure a consistent positive experience.'
  }
  return (
    <ResponseHandler {...result}>
      {({ insightsKeyDemographics }) => {
        if (!insightsKeyDemographics.length) return <div />
        const showCarousel = insightsKeyDemographics.length > 1
        // Pass in the top performing group to the modal
        let bottomDemographicGroup
        if (modalDemographic) {
          const demographicData = insightsKeyDemographics.find(
            demographic => demographic.dataType === modalDemographic,
          )
          bottomDemographicGroup =
            sortBy(demographicData?.scores, 'benchmark_positive')[0].label || ''
        }
        return (
          <div className={classes.fullRow} id="key-demographics-snap">
            {modalDemographic && (
              <KeyDemographicsModalChart
                survey={survey}
                onClose={() => setModalDemographic(null)}
                filters={filters}
                dtCode={modalDemographic}
                benchmark={benchmark}
                bottomDemographicGroup={bottomDemographicGroup}
              />
            )}
            <SnapshotChartHeader
              title={title}
              description={description}
              tooltip={tooltip}
              // Determine the snap id using `step`, since we need to capture charts hidden in the carousel.
              snapId={`key-demographics-snap-${step}`}
              screenshotStrategy="html2canvas"
              hasTimeTrending={hasTimeTrending}
              timeTrendingChartKey={timeTrendingChartKey}
              extraControls={
                <TextDropdown
                  id="key-demographics-dropdown"
                  value={impactDropdownStrategy}
                  renderValue={(strategy: unknown) => {
                    return `Show: ${getStrategyLabel(
                      strategy as ImpactStrategyType,
                      survey.productType,
                    )}`
                  }}
                  onChange={e => {
                    setImpactDropdownStrategy(e.target.value as ImpactStrategyType)
                  }}
                >
                  {Object.keys(ImpactStrategyType)
                    .filter((strategy): strategy is ImpactStrategyType => {
                      return validStrategies.includes(strategy as ImpactStrategyType)
                    })
                    .map(strategy => {
                      return (
                        <MenuItem key={strategy} value={strategy}>
                          {getStrategyLabel(strategy, survey.productType)}
                        </MenuItem>
                      )
                    })}
                </TextDropdown>
              }
            />
            <Carousel
              showCarousel={showCarousel}
              onStepChange={setStep}
              numSteps={insightsKeyDemographics.length}
            >
              {insightsKeyDemographics.map(({ dataType, scores, label }) => {
                let chartLabel = label
                // Indicate to the user that the birth year question applied to residents and not representatives.
                if (dataType === DataTypeCode.SENIOR_BIRTH_YEAR) {
                  chartLabel += ' (Senior)'
                }
                const chartData = scores
                  // Omit < min from the graph
                  .filter(score => score.benchmarkPositive !== MIN_SHOWABLE_RESULTS_CODE)
                  .map(score => ({
                    ...score,
                    positive: score.benchmarkPositive, // Assign the "impact_score"
                    originalPositive: score.positive, // Maintain the old positive score for display on the tooltip
                    color:
                      impactDropdownStrategy === ImpactStrategyType.NPS_SCORE
                        ? colors.navy
                        : '#44888B',
                  }))
                let chartProps = {}
                if (impactDropdownStrategy === ImpactStrategyType.NPS_SCORE) {
                  chartProps = {
                    chartData,
                    chartType: 'line',
                    xAxisRange: [-100, 100],
                    numXAxisBars: 9,
                    xAxisIsPercentage: false,
                    extraLegends: [
                      {
                        color: colors.navy,
                        name: `${getNpsAbbreviation(survey.productType)} Score`,
                        icon: `image://${BENCHMARK_LINE_TYPES[1]}`,
                      },
                    ],
                  }
                }
                const totalResponses = chartData.reduce((acc, score) => acc + (score.count || 0), 0)

                return (
                  <div key={dataType} className={classes.chartContainer}>
                    <div className={classes.chartHeader}>
                      <Typography variant="subtitle1">
                        {chartLabel || DATA_TYPE_NAMES[dataType]}
                      </Typography>
                      <Button
                        color="secondaryNoBackground"
                        onClick={() => {
                          gaEvent({
                            action: 'seeMoreKeyDemographics',
                            category: 'Snapshot',
                          })
                          setModalDemographic(dataType)
                        }}
                        className={classes.seeMoreModalButton}
                        id={`see-more-key-demographics-${dataType}`}
                      >
                        See More
                      </Button>
                      {impactDropdownStrategy === ImpactStrategyType.NUM_RESPONSES && (
                        <Typography
                          className={classes.chartSubtitle}
                          variant="body2"
                          color="textSecondary"
                        >
                          {numberWithCommas(totalResponses)} Total Responses
                        </Typography>
                      )}
                    </div>
                    <HorizontalBarChart
                      {...chartProps}
                      chartWidth="100%"
                      chartData={formatChartData([chartData])}
                      chartHeight={260}
                      pageSize={6}
                      numXAxisBars={5}
                      paginationWidth={220}
                      yAxisLabelLength={36}
                      axisFontSize={12}
                      tooltipFormatter={(series: TooltipSeries) => {
                        const score = chartData.find(d => d.label === series[0].data.name)
                        if (!score?.label) return null
                        let rows: TooltipRow[] = []
                        if (impactScoreStrategy === ImpactScoreStrategyEnum.POSITIVE_SCORE) {
                          rows = [
                            {
                              value: formatTooltipScore(score.positive, survey.minShowableResults),
                              label: `${getStrategyLabel(
                                impactDropdownStrategy,
                                survey.productType,
                              )}:`,
                              color: colors.navy,
                            },
                            {
                              value: formatTooltipScore(score.positive, survey.minShowableResults),
                              label: 'Positive:',
                              color: colors.success,
                            },
                            {
                              value: formatTooltipScore(
                                score.inconsistent,
                                survey.minShowableResults,
                              ),
                              label: 'Inconsistent:',
                              color: colors.warning,
                            },
                            {
                              value: formatTooltipScore(score.negative, survey.minShowableResults),
                              label: 'Negative:',
                              color: colors.danger,
                            },
                          ]
                        } else if (impactScoreStrategy === ImpactScoreStrategyEnum.NPS_SCORE) {
                          rows = [
                            {
                              label: `${getNpsAbbreviation(survey.productType)} Score`,
                              value: `${getNpsAbbreviation(survey.productType)} ${Math.round(
                                score.positive || 0,
                              )}`,
                            },
                            {
                              label: 'Detractors',
                              value: formatTooltipScore(score.negative, survey.minShowableResults),
                              color: NPS_COLORS[NpsGroupsEnum.DETRACTORS],
                            },
                            {
                              label: 'Passives',
                              value: formatTooltipScore(
                                score.inconsistent,
                                survey.minShowableResults,
                              ),
                              color: colors.mustardYellow,
                            },
                            {
                              label: 'Promoters',
                              value: formatTooltipScore(
                                score.originalPositive,
                                survey.minShowableResults,
                              ),
                              color: NPS_COLORS[NpsGroupsEnum.PROMOTERS],
                            },
                          ]
                          return (
                            <StandardTooltip
                              title={score.label}
                              description={`Survey: ${survey.name}`}
                              rows={rows}
                            />
                          )
                        } else {
                          rows = [
                            {
                              label: 'Responses:',
                              value: `${score.count} (${Math.round(
                                score.positive || 0,
                              )}% of responses)`,
                            },
                          ]
                        }
                        return (
                          <StandardTooltip
                            title={score.label}
                            description={`Survey: ${survey.name}`}
                            rows={rows}
                          />
                        )
                      }}
                    />
                  </div>
                )
              })}
            </Carousel>
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default KeyDemographics
